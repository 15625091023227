@font-face {
  font-family: "Nocturno";
  src: url("assets/Nocturno Text Pro Regular Italic.woff2") format("woff2"),
}

@font-face {
  font-family: "Plain";
  src: url("assets/Plain-Regular.woff2") format("woff2"),
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nocturno', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}


.page-transition-container {
  > div {

    .fade {
      opacity: 0;
      transition: all 2s;
    }

    &.page-transitions-exit {
      opacity: 1;
      transition: all 2s;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1000;
    }

    &.page-transitions-enter {
      opacity: 0;
      transition: all 2s 1s;
    }

    &.page-transitions-appear {
      opacity: 0;
      transition: all 2s 1s;
    }

    &.page-transitions-appear-active,
    &.page-transitions-enter-active,
    &.page-transitions-enter-done {
      opacity: 1;

      .fade {
        opacity: 1;
      }
    }

    &.page-transitions-exit-active,
    &.page-transitions-exit-done {
      transition: all 0.5s;
      opacity: 0;
    }
  }
}



#questions {
  width: 150vw;
  height: 100vh;
  display: none;

  &.q-radio,
  &.q-rating {
    display: block;
  }

  svg.container {
    width: 100%;
    height: 100%;

    &.checkbox {
      .leaf {
        &.primary {
          circle.target {
            fill: white;
          }
        }
      }
    }
  }

  .expander {
    // fill: #B19DDF;
  }

  g.buttonContainer {
    width: 100%;
    height: 60%;
    transform: translateY(30vh);
  }

  g.textContainer {
    // display: block;
    // transform: translateY(-50%);
  }
}

.leaf {
  .buttonInner {
    transform: scale(0);
    opacity: 0;
  }

  .expander {
    // fill: #D2C0F9;
  }

  circle.debug {
    fill: pink;
  }

  &.primary {
    circle.target {
      fill: transparent;
      stroke: #B19DDF;
      stroke-width: 1.5px;
    }

    text {
      font-size: 18px;
      letter-spacing: -0.02em;
      line-height: 24px;
    }
  }

  &.secondary {
    text {
      font-size: 16px;
    }

    circle.target {
      fill: #D2C0F9;
    }
  }

  &.empty {
    circle.target {
      fill: transparent;
    }
  }

  text {
    font-size: 20px;
  }
}

a {
  color: black;
  text-decoration: none;
}

.question-title {
  transition: opacity 2s !important;

  .line {
    display: block;
    transition: none !important;
  }

  .word {
    transition-property: opacity;
  }

  .l {
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-out;
  }

  &.loaded {
    .l {
      opacity: 1;
    }
  }

  &.small-title {
    font-size: 28px;
  }
}


.app-bg-container {
  transition: all 2s;
  will-change: transform;
}

/*
body.ReactModal__Body--open {
  &:not(.modal-closing) {
    .app-bg-container {
      filter: blur(25px);
    }
  }
}
*/

.ReactModalPortal {
  position: relative;
  z-index: 1000;
}

.ReactModal__Overlay {
  transform: translateY(-100%);
  transition: all 1s;
  will-change: transform;
  // background: transparent !important;

  &.ReactModal__Overlay--after-open {
    transform: translateY(0);
  }

  &.ReactModal__Overlay--before-close {
    transform: translateY(-100%);
  }

  .ReactModal__Content {
    // background: transparent !important;
    border: 0 !important;
  }
}

.ModalContent {
  font-family: Plain, Helvetica, sans-serif;
  height: calc(65vh - 100px);
  overflow: auto;
}

.ModalButton {
  width: 120px;
  height: 120px;
  // background: white;
  border: 1px solid #B19DDF;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  border-radius: 50%;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 24px;
  }
}


@keyframes scale {
  0%, 50%, 100% {
    transform: scale(0.9);
  }
  25%, 75% {
    transform: scale(1.1);
  }
}

@keyframes rotate {
  0% {
    rotate: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media screen and (max-width: 400px) {
  .ModalContent {
    max-height: 55vh;
  }

  .question-title {
    font-size: 20px;

    &.small-title {
      font-size: 20px;
    }
  }

  #questions {
    g.buttonContainer {
      width: 100%;
      height: calc(65vh - 200px);
      transform: translateY(28vh);
    }
  }
}